const colorMap = {
  // Primary and secondary colors.
  Navy: `primary.dark-blue`,
  'Froneri Blue': `primary.froneri-blue`,
  Pink: `secondary.pink`,
  Orange: `secondary.orange`,
  Green: `secondary.green`,
  Yellow: `secondary.yellow`,
  'Light Grey': `secondary.light-grey`,
  White: `white`,
  // Brand colors.
  Oreo: `brand.oreo`,
  'Drumstick & Extrême': `brand.extreme`,
  Nuii: `brand.nuii`,
  'Häagen-Dazs': `brand.haagen-dazs`,
  'Outshine & Rowntree': `brand.outshine`,
  "Cadbury's": `brand.cadburys`,
  Dreyers: `brand.dreyers`,
  'Dreyers Alt': `brand.dreyers-alt`,
  'Skinny Cow': `brand.skinny-cow`,
  'Skinny Cow Alt': `brand.skinny-cow-alt`,
  Aino: `brand.aino`,
  Fazer: `brand.fazer`,
  PINGVIININ: `brand.pingviinin`,
  Milka: `brand.milka`,
  'Milka (Dark)': `brand.milka-dark`,
  'La Laitiere': `brand.la-laitiere`,
  Maxibon: `brand.maxibon`,
  KITKAT: `brand.kitkat`,
  'La lechera': `brand.la-lechera`,
  Antica: `brand.antica`
};

/**
 * A helper function that determines the Chakra UI color to use based on a CMS field value.
 * @param {string} value The CMS field value.
 * @returns {string} The Chakra UI color to use.
 */
function getColorFromField(value) {
  return colorMap[value];
}

export default getColorFromField;
